import { css } from 'styled-components';

export const sizes = {
  mobilesmall: '360px',
  mobile: '480px',
  tablet: '768px',
  fablet: '1080px',
  desktop: '1280px',
};

const themeCommon = {
  shadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
  shadowSmall: '0px 5px 10px rgba(0, 0, 0, 0.05)',
  fontFamily:
    '"Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,sans-serif',
  secondaryFontFamily:
    '"Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,sans-serif',
  spacing: {
    sectionBottom: 'margin-bottom: 1rem',
    sectionTop: 'margin-top: 1rem',
    sectionTopBottom: css`
      margin-top: 1rem;
      margin-bottom: 1rem;
    `,
  },
  media: {
    mobilesmall: `(max-width: ${sizes.mobilesmall})`,
    mobile: `(max-width: ${sizes.mobile})`,
    tablet: `(max-width: ${sizes.tablet})`,
    fablet: `(max-width: ${sizes.fablet})`,
    desktop: `(max-width: ${sizes.desktop})`,
    minMobile: `(min-width: ${sizes.mobile})`,
    minTablet: `(min-width: ${sizes.tablet})`,
    minFablet: `(min-width: ${sizes.fablet})`,
  },
  gradient: 'linear-gradient(134deg, #FF4949 0%, #ff2222 99%)',
  gradient2: 'linear-gradient(99deg, #FF4949 0%, #ff2222 130%)',
  swapIfDark(prop1, prop2) {
    return `${this.dark ? this[prop1] : this[prop2]};`;
  },
};

export const themedark = {
  dark: true,
  bg: '#191919',
  primaryColor: '#FF4949',
  secondaryColor: '#232323',
  accentColor: '#303030',
  skillColor: '#828282',
  primaryBlack: '#F8F8F8',
  elevation0: '#191919',
  primaryText: '#F8F8F8',
  scrlbackground: '#191919',
  scrlthumbhover: '#ff2222',
  scrlthumb: '#FF4949',
  speedColor: '#1e1e1e',
  ...themeCommon,
};

export const themelight = {
  dark: false,
  bg: '#F8F8F8',
  primaryColor: '#FF4949',
  secondaryColor: '#F8F8F8',
  accentColor: '#EAEAEA',
  skillColor: '#707070',
  primaryBlack: '#383838',
  primaryText: '#383838',
  scrlbackground: '#F8F8F8',
  scrlthumbhover: '#ff2222',
  scrlthumb: '#FF4949',
  speedColor: '#fbfbfb',
  ...themeCommon,
};

export const themedarkblue = {
  dark: true,
  bg: '#0B0D18',
  primaryColor: '#FF4949',
  secondaryColor: '#0F1221',
  accentColor: '#15182E',
  primaryBlack: '#F8F8F8',
  elevation0: '#FF4949',
  primaryText: '#F8F8F8',
  ...themeCommon,
};

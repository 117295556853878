import React from 'react';
// import loadable from '@loadable/component';
import logo from '@src/static/logo.svg';
import Link from 'gatsby-link';
import { NavItems, NavItem } from './Navbar.style';
import NavLinks from './NavLinks';
import ToggleSwitch from '@common/ToggleSwitch';
import { animateScroll as scroll } from 'react-scroll';

// const scroll = loadable(() =>
//   import('react-scroll').then(module => module.animateScroll)
// );

const NavDesktop = () => {
  const options = {
    duration: 500,
    smooth: true,
  };

  const scrollToTop = () => {
    scroll.scrollToTop(options); // Use dynamically loaded scroll function
  };

  return (
    <>
      <Link onClick={scrollToTop} className="logo" offset={-100} to="/">
        <img src={logo} alt="SEELAY" />
      </Link>

      <nav>
        <NavItems>
          <NavLinks NavItem={NavItem} />

          <NavItem>
            <Link to="/blog">Blog</Link>
          </NavItem>
          <NavItem>
            <ToggleSwitch />
          </NavItem>
        </NavItems>
      </nav>
    </>
  );
};

export default NavDesktop;
